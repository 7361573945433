import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import Link from '../../components/Link';
import PointSystemToggle, {
  PointSystem,
} from '../../components/PointSystemToggle';
import { getUsers } from '../../firebase/api';
import PlayerNameAutocomplete from './PlayerNameAutocomplete';

export default function JudgeCircleCreatePage() {
  const navigate = useNavigate();
  const [pointSystem, setPointSystem] = useState<PointSystem>('lives');
  const [maxPoints, setMaxPoints] = useState(0);
  const [names, setNames] = useState(['']);

  const isLivesPointSystem = pointSystem === 'lives';

  const [playerNames, setPlayerNames] = useState<readonly string[]>([]);

  const playerOptions = useMemo(
    () => playerNames.filter(name => !names.includes(name)),
    [names, playerNames],
  );

  function resetForm() {
    setPointSystem('lives');
    setMaxPoints(0);
    setNames(['']);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const initialState = {
      pointSystem,
      maxPoints,
      players: names.map(name => ({
        id: crypto.randomUUID(),
        name,
        points: isLivesPointSystem ? maxPoints : 0,
        warnings: 0,
        ranking: 1,
      })),
    };

    localStorage.setItem('tools.judgeCircle', JSON.stringify(initialState));
    navigate('judge-circle');
    resetForm();
  }

  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = await getUsers();
      setPlayerNames(
        fetchedUsers
          .flatMap(user => user.names)
          .toSorted((a, b) => a.localeCompare(b)),
      );
    };

    fetchUsers();
  }, []);

  return (
    <Container component="main" maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <Stack gap={4} p={2}>
          <Heading>Döm cirkel</Heading>

          <PointSystemToggle value={pointSystem} onChange={setPointSystem} />

          <TextField
            label={
              isLivesPointSystem ? 'Antal liv' : 'Antal poäng för att åka ut'
            }
            type="number"
            value={maxPoints || ''}
            onChange={event => setMaxPoints(Number(event.target.value))}
            required
            inputProps={{ min: 1 }}
          />

          <div>
            <Stack gap={4}>
              {names.map((playerName, index) => (
                <PlayerNameAutocomplete
                  key={index}
                  index={index}
                  playerName={playerName}
                  names={names}
                  setNames={setNames}
                  playerOptions={playerOptions}
                />
              ))}
            </Stack>

            <Box display="flex" justifyContent="flex-end" mt={1}>
              <Typography variant="body2">
                <Link
                  variant="body2"
                  onClick={() => setNames([...names, ''])}
                  sx={{ textDecoration: 'none' }}
                >
                  Lägg till +
                </Link>
              </Typography>
            </Box>
          </div>

          <Button type="submit" variant="contained" sx={{ mb: 2 }}>
            Döm cirkel
          </Button>
        </Stack>
      </form>
    </Container>
  );
}
