import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { signOut } from '../../firebase/auth';
import EditProfileDrawer from './EditProfileDrawer';

export interface AccountMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
}

export const AccountMenu = ({ anchorEl, open, onClose }: AccountMenuProps) => {
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [editProfileDrawerOpen, setEditProfileDrawerOpen] = useState(false);

  const handleSignOutClick = async () => {
    if (isSigningOut) return;

    setIsSigningOut(true);

    try {
      await signOut();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSigningOut(false);
    }
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
        <MenuItem onClick={() => setEditProfileDrawerOpen(true)}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Redigera profil
        </MenuItem>
        <MenuItem onClick={handleSignOutClick}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logga ut
        </MenuItem>
      </Menu>

      <EditProfileDrawer
        open={editProfileDrawerOpen}
        onClose={() => setEditProfileDrawerOpen(false)}
      />
    </>
  );
};
