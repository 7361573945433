import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/sv';
import { PwaPrompt } from 'react-ios-pwa-prompt-ts';
import AuthProvider from './contexts/authContext';
import { Router } from './pages/Router';
import { theme } from './theme';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
          <Router />
          <PwaPrompt />
        </LocalizationProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
