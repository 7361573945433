import { Container, Stack } from '@mui/material';
import { useState } from 'react';
import { Player } from '../../components/PlayerCircle/PlayerCircle';
import TeamCircle from '../../components/PlayerCircle/TeamCircle';
import { PointSystem } from '../../components/PointSystemToggle';
import JudgeCrossInstructions from './JudgeCrossInstructions';
import TeamLeaderboard from './TeamLeaderboard';

export type JudgeCrossState = {
  pointSystem: PointSystem;
  maxPoints: number;
  players: readonly Player[];
};

export interface JudgeCrossPageProps {
  storedState: JudgeCrossState;
}

export default function JudgeCirclePage({ storedState }: JudgeCrossPageProps) {
  const [currentState, setCurrentState] =
    useState<JudgeCrossState>(storedState);
  localStorage.setItem('tools.judgeCross', JSON.stringify(currentState));

  const { pointSystem, maxPoints, players } = currentState;
  const isLivesPointSystem = pointSystem === 'lives';

  const teams = players;

  const setTeams = (teams: readonly Player[]) =>
    setCurrentState({ ...currentState, players: teams });

  return (
    <Container component="main" maxWidth="sm">
      <Stack gap={6} mb={4}>
        <JudgeCrossInstructions isLivesPointSystem={isLivesPointSystem} />
        <TeamCircle
          teams={teams}
          setTeams={setTeams}
          isLivesPointSystem={isLivesPointSystem}
          maxPoints={maxPoints}
        />
        <TeamLeaderboard
          teams={teams}
          isLivesPointSystem={isLivesPointSystem}
          maxPoints={maxPoints}
        />
      </Stack>
    </Container>
  );
}
