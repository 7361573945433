import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert, IconButton, InputAdornment, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormEvent, useState } from 'react';
import Link from '../../components/Link';
import { Spinner } from '../../components/Spinner';
import { signInUser } from '../../firebase/auth';
import ResetPasswordDrawer from './ResetPasswordDrawer';

export interface SignInFormProps {
  email: string;
  password: string;
  toggleForm: () => void;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
}

export default function SignInForm({
  email,
  password,
  toggleForm,
  setEmail,
  setPassword,
}: SignInFormProps) {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [resetPasswordDrawerOpen, setResetPasswordDrawerOpen] = useState(false);

  const handlePasswordVisibilityClick = () => setShowPassword(!showPassword);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) return;

    setLoading(true);

    try {
      await signInUser(email, password);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Stack sx={{ mt: 8, alignItems: 'center' }}>
        {!loading ? (
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
        ) : (
          <Spinner sx={{ m: 0.75 }} />
        )}

        <Typography component="h1" variant="h5">
          Logga in
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {errorMessage !== undefined && (
            <Alert severity="error" onClose={() => setErrorMessage(undefined)}>
              {errorMessage}
            </Alert>
          )}

          <TextField
            id="email"
            name="email"
            label="Email"
            autoComplete="email"
            margin="normal"
            value={email}
            onChange={event => setEmail(event.target.value)}
            required
            fullWidth
          />

          <TextField
            id="password"
            name="password"
            label="Lösenord"
            type={showPassword ? 'text' : 'password'}
            margin="normal"
            autoComplete="current-password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handlePasswordVisibilityClick}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon titleAccess="Dölj lösenord" />
                    ) : (
                      <VisibilityIcon titleAccess="Visa lösenord" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Kom ihåg mig"
            /> */}

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            Logga in
          </Button>

          <Grid container>
            <Grid item xs>
              <Link
                variant="body2"
                onClick={() => setResetPasswordDrawerOpen(true)}
              >
                Glömt lösenord?
              </Link>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Inget konto?{' '}
                <Link variant="body2" onClick={toggleForm}>
                  Skapa konto
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <ResetPasswordDrawer
        open={resetPasswordDrawerOpen}
        onClose={() => setResetPasswordDrawerOpen(false)}
      />
    </Container>
  );
}
