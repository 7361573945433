import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  FormHelperText,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { FormEvent, useState } from 'react';
import { useAuth } from '../../contexts/authContext';
import { NAME_SEPARATOR, updateUser } from '../../firebase/api';
import Drawer from '../Drawer';
import { Heading } from '../Heading';
import Link from '../Link';

const THIRTY_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 30;

export interface EditProfileDrawerProps {
  open: boolean;
  onClose: () => void;
}

export default function EditProfileDrawer({
  open,
  onClose,
}: EditProfileDrawerProps) {
  const { user } = useAuth();
  const [names, setNames] = useState(
    user?.names !== undefined && user?.names.length > 0 ? user.names : [''],
  );
  const [associations, setAssociations] = useState(
    user?.associations !== undefined && user?.associations.length > 0
      ? user.associations
      : [''],
  );
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const areNamesReadOnly =
    Date.now() - (user?.nameModifiedAt ?? 0) < THIRTY_DAYS_IN_MS;

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (loading || user === null) return;

    setLoading(true);

    try {
      await updateUser({
        id: user.id,
        names: names.map(name => name.trim()),
        associations: associations
          .map(association => association.trim())
          .filter(association => association !== ''),
        ...(names.join(NAME_SEPARATOR) !== user?.nameLabel && {
          nameModifiedAt: Date.now(),
        }),
      });
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <Container component="main" maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <Stack gap={4} p={2}>
            <Heading>Redigera profil</Heading>

            {errorMessage !== undefined && (
              <Alert
                severity="error"
                onClose={() => setErrorMessage(undefined)}
              >
                {errorMessage}
              </Alert>
            )}

            <div>
              <Stack gap={4}>
                {names.map((name, index) => (
                  <TextField
                    key={index}
                    id="displayName"
                    name="displayName"
                    label="Visningsnamn"
                    value={name}
                    onChange={event => {
                      const newNames = [...names];
                      newNames[index] = event.target.value;
                      setNames(newNames);
                    }}
                    autoFocus={index > 0}
                    disabled={areNamesReadOnly}
                    required
                    fullWidth
                    InputProps={{
                      endAdornment:
                        !areNamesReadOnly && names.length > 1 ? (
                          <InputAdornment position="end">
                            <CloseIcon
                              fontSize="small"
                              aria-label="Ta bort namn"
                              onClick={() => {
                                const newNames = [...names];
                                newNames.splice(index, 1);
                                setNames(newNames);
                              }}
                            />
                          </InputAdornment>
                        ) : undefined,
                    }}
                  />
                ))}
              </Stack>

              <FormHelperText sx={{ mx: 1.75 }}>
                OBS: Namn kan endast ändras en gång på 30 dagar.
              </FormHelperText>

              {!areNamesReadOnly && (
                <Box display="flex" justifyContent="flex-end" mt={1}>
                  <Typography variant="body2">
                    <Link
                      variant="body2"
                      onClick={() => setNames([...names, ''])}
                      sx={{ textDecoration: 'none' }}
                    >
                      Lägg till +
                    </Link>
                  </Typography>
                </Box>
              )}
            </div>

            <div>
              <Stack gap={4}>
                {associations.map((association, index) => (
                  <TextField
                    key={index}
                    id="association"
                    name="association"
                    label="Förening"
                    value={association}
                    onChange={event => {
                      const newAssociations = [...associations];
                      newAssociations[index] = event.target.value;
                      setAssociations(newAssociations);
                    }}
                    autoFocus={index > 0}
                    fullWidth
                    InputProps={{
                      endAdornment:
                        associations.length > 1 ? (
                          <InputAdornment position="end">
                            <CloseIcon
                              fontSize="small"
                              aria-label="Ta bort förening"
                              onClick={() => {
                                const newAssociation = [...associations];
                                newAssociation.splice(index, 1);
                                setAssociations(newAssociation);
                              }}
                            />
                          </InputAdornment>
                        ) : undefined,
                    }}
                  />
                ))}
              </Stack>

              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Typography variant="body2">
                  <Link
                    variant="body2"
                    onClick={() => setAssociations([...associations, ''])}
                    sx={{ textDecoration: 'none' }}
                  >
                    Lägg till +
                  </Link>
                </Typography>
              </Box>
            </div>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Uppdatera
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </Drawer>
  );
}
