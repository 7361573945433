import { Button, Container, Stack, TextField } from '@mui/material';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../components/Heading';
import PointSystemToggle, {
  PointSystem,
} from '../../components/PointSystemToggle';

export default function JudgeCrossCreatePage() {
  const navigate = useNavigate();
  const [pointSystem, setPointSystem] = useState<PointSystem>('points');
  const [maxPoints, setMaxPoints] = useState(0);
  const [team1, setTeam1] = useState('');
  const [team2, setTeam2] = useState('');

  const isLivesPointSystem = pointSystem === 'lives';

  function resetForm() {
    setPointSystem('points');
    setMaxPoints(0);
    setTeam1('');
    setTeam2('');
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const initialState = {
      pointSystem,
      maxPoints,
      players: [team1, team2].map(name => ({
        id: crypto.randomUUID(),
        name,
        points: isLivesPointSystem ? maxPoints : 0,
        warnings: 0,
        ranking: 1,
        teamName: name,
      })),
    };

    localStorage.setItem('tools.judgeCross', JSON.stringify(initialState));
    navigate('judge-cross');
    resetForm();
  }

  return (
    <Container component="main" maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <Stack gap={4} p={2}>
          <Heading>Döm korscaps</Heading>

          <PointSystemToggle value={pointSystem} onChange={setPointSystem} />

          <TextField
            label={
              isLivesPointSystem ? 'Antal liv' : 'Antal poäng för att åka ut'
            }
            type="number"
            value={maxPoints || ''}
            onChange={event => setMaxPoints(Number(event.target.value))}
            required
            inputProps={{ min: 1 }}
          />

          <TextField
            label="Lag 1"
            value={team1}
            onChange={event => setTeam1(event.target.value)}
            required
            fullWidth
          />

          <TextField
            label="Lag 2"
            value={team2}
            onChange={event => setTeam2(event.target.value)}
            required
            fullWidth
          />

          <Button type="submit" variant="contained" sx={{ mb: 2 }}>
            Döm korscaps
          </Button>
        </Stack>
      </form>
    </Container>
  );
}
