import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'capsat-project.firebaseapp.com',
  projectId: 'capsat-project',
  storageBucket: 'capsat-project.appspot.com',
  messagingSenderId: '228459501821',
  appId: '1:228459501821:web:f1c2414f5486a7a34ac694',
  measurementId: 'G-78P80DZNTG',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };
