import { Typography } from '@mui/material';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isEliminated } from '../../components/PlayerCircle/PlayerCircle';
import { Team } from '../../components/PlayerCircle/TeamCircle';
import { BACKGROUND_COLOR } from '../../theme';

const styles = {
  tableHead: {
    '& > th': { backgroundColor: BACKGROUND_COLOR },
  },
  disabledRanking: {
    color: 'text.disabled',
  },
};

export interface TeamLeaderboardProps {
  teams: readonly Team[];
  isLivesPointSystem: boolean;
  maxPoints: number;
}

export default function TeamLeaderboard({
  teams,
  isLivesPointSystem,
  maxPoints,
}: TeamLeaderboardProps) {
  const sortedTeams = teams.toSorted((a, b) => a.ranking - b.ranking);

  const isTeamInTheGame = (team: Team) =>
    !isEliminated(isLivesPointSystem, team.points, maxPoints);

  const gameOver = teams.filter(isTeamInTheGame).length <= 1;

  return (
    <TableContainer>
      <Table size="small" aria-label="leaderboard" stickyHeader>
        <TableHead>
          <TableRow sx={styles.tableHead}>
            <TableCell align="left"></TableCell>
            <TableCell align="left">Lag</TableCell>
            <TableCell align="right">
              {isLivesPointSystem ? 'Liv' : 'Poäng'}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedTeams.map(team => (
            <TableRow key={team.id}>
              <TableCell
                sx={[
                  isTeamInTheGame(team) && !gameOver && styles.disabledRanking,
                ]}
              >
                #{team.ranking}
              </TableCell>
              <TableCell>
                <Typography>{team.name}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{Math.round(team.points)}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
