import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { theme } from '../../theme';
import Judge from './JudgePiece';
import { Player, isEliminated } from './PlayerCircle';
import PlayerPiece from './PlayerPiece';

export interface Animation {
  id: number;
}

export type Team = Player;

const calculateRankings = (
  teams: readonly Team[],
  isLivesPointSystem: boolean,
  maxPoints: number,
) => {
  const sortedTeams = teams.toSorted((a, b) =>
    isLivesPointSystem ? b.points - a.points : a.points - b.points,
  );

  const isTeamEliminated = (team: Team) =>
    isEliminated(isLivesPointSystem, team.points, maxPoints);

  const eliminatedTeams = sortedTeams.filter(isTeamEliminated);

  return teams.map(team => {
    const rankingIndex = sortedTeams.findIndex(
      ({ points }) => points === team.points,
    );

    const eliminatedTeamsWithHigherRanking = eliminatedTeams.filter(
      ({ ranking }) => ranking > team.ranking,
    );

    return {
      ...team,
      ranking: !isTeamEliminated(team)
        ? rankingIndex + 1
        : teams.length - eliminatedTeamsWithHigherRanking.length,
    };
  });
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  circleWrapper: {
    position: 'relative',
    width: '100%',
    height: '70vw',
    borderRadius: '50%',
    marginX: 'auto',
  },
  cross: {
    position: 'relative',
    width: '6rem',
    height: '6rem',
    top: 14,

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      left: '50%',
      width: '2px',
      height: '100%',
      backgroundColor: theme.palette.action.active,
    },

    '&::before': {
      transform: 'rotate(45deg)',
    },

    '&::after': {
      transform: 'rotate(-45deg)',
    },
  },
  circle: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderRadius: '50%',
    top: '50%',
    left: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transformStyle: 'preserve-3d',
  },
  growingWarning: {
    position: 'absolute',
    right: 1.35,
    top: 0,
    fontSize: '16px',
    whiteSpace: 'nowrap',
    animation: 'grow 2s forwards',

    '@keyframes grow': {
      '100%': {
        transform: 'scale(4) translateY(-2px)',
        opacity: 0,
      },
    },
  },
};

export interface TeamCirclePlayerProps {
  player: Player;
  angle: number;
  setTeamPoints: (teamId: string, newPoints: number) => void;
  setTeamWarnings: (teamId: string, newWarnings: number) => void;
  isLivesPointSystem: boolean;
  maxPoints: number;
}

function TeamCirclePlayer({
  player,
  angle,
  setTeamPoints,
  setTeamWarnings,
  isLivesPointSystem,
  maxPoints,
}: TeamCirclePlayerProps) {
  const [animatedWarnings, setAnimatedWarnings] = useState<Animation[]>([]);

  const { name, warnings } = player;
  const transform = `rotate(${angle}deg) translateX(min(30vw, 300px)) rotate(${-angle}deg)`;

  return (
    <>
      <Box sx={[styles.circle, { transform }]}>
        <PlayerPiece
          player={player}
          setPoints={setTeamPoints}
          setWarnings={setTeamWarnings}
          setAnimatedWarnings={setAnimatedWarnings}
          isLivesPointSystem={isLivesPointSystem}
          maxPoints={maxPoints}
        />
      </Box>

      <Stack
        sx={[
          styles.circle,
          {
            transform,
            zIndex: 2,
            mt: '3.2rem',
            pointerEvents: 'none',
          },
        ]}
      >
        <Typography
          variant="caption"
          textAlign="center"
          whiteSpace="nowrap"
          fontWeight={500}
          lineHeight={1}
        >
          {name}
        </Typography>

        <Box position="relative" component="span" height={0}>
          {warnings > 0 && (
            <>
              {'⚠️'.repeat(warnings)}
              {animatedWarnings.map(warning => (
                <Box key={warning.id} sx={styles.growingWarning}>
                  ⚠️
                </Box>
              ))}
            </>
          )}
        </Box>
      </Stack>
    </>
  );
}

export interface TeamCircleProps {
  teams: readonly Team[];
  setTeams: (newTeams: Team[]) => void;
  isLivesPointSystem: boolean;
  maxPoints: number;
}

export default function TeamCircle({
  teams,
  setTeams,
  isLivesPointSystem,
  maxPoints,
}: TeamCircleProps) {
  const players = teams.flatMap(team => [team, team]);

  const setTeamPoints = (teamId: string, newPoints: number) => {
    const newTeams = teams.map(team =>
      team.id === teamId ? { ...team, points: newPoints } : team,
    );

    setTeams(calculateRankings(newTeams, isLivesPointSystem, maxPoints));
  };

  const setTeamWarnings = (teamId: string, newWarnings: number) => {
    const newTeams = teams.map(team =>
      team.id === teamId ? { ...team, warnings: newWarnings } : team,
    );

    setTeams(newTeams);
  };

  return (
    <Stack sx={styles.container}>
      <Box sx={styles.circleWrapper}>
        {players.map((player, index) => {
          const angle = 90 + (180 * (2 * index + 1)) / players.length;

          return (
            <TeamCirclePlayer
              key={player.id + index}
              player={player}
              angle={angle}
              setTeamPoints={setTeamPoints}
              setTeamWarnings={setTeamWarnings}
              isLivesPointSystem={isLivesPointSystem}
              maxPoints={maxPoints}
            />
          );
        })}

        <Box sx={styles.circle}>
          <Box sx={styles.cross} />
        </Box>
      </Box>

      <Judge />
    </Stack>
  );
}
