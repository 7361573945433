import Drawer from '../../../components/Drawer';
import JudgeCrossPage, {
  JudgeCrossState,
} from '../../JudgeCrossPage/JudgeCrossPage';

export interface JudgeCrossDrawerProps {
  open: boolean;
  onClose: () => void;
  storedState: JudgeCrossState;
}

export default function JudgeCrossDrawer({
  open,
  onClose,
  storedState,
}: JudgeCrossDrawerProps) {
  return (
    <Drawer open={open} onClose={onClose}>
      <JudgeCrossPage storedState={storedState} />
    </Drawer>
  );
}
