import { Container, Stack, Typography } from '@mui/material';
import { Heading } from '../../components/Heading';
import JudgeCircleTool from './JudgeCircleTool/JudgeCircleTool';
import JudgeCrossTool from './JudgeCrossTool/JudgeCrossTool';

export default function ToolsPage() {
  return (
    <Container component="main" maxWidth="sm">
      <Stack gap={4} p={2}>
        <Heading>Verktyg</Heading>

        <Typography variant="body2" color="textSecondary" textAlign="center">
          OBS: Verktyg påverkar inte rating
        </Typography>

        <JudgeCircleTool />

        <JudgeCrossTool />
      </Stack>
    </Container>
  );
}
