import { createTheme } from '@mui/material/styles';

export const BACKGROUND_COLOR = '#fafafa';

export const theme = createTheme({
  palette: {
    success: {
      main: '#3fb950',
    },
    error: {
      main: '#f85149',
    },
    action: {
      active: '#8d96a0',
    },
  },
  typography: {
    h4: {
      fontSize: '1.8rem',
    },
  },
});
