import ToolsIcon from '@mui/icons-material/Construction';
import CreateIcon from '@mui/icons-material/Create';
import InfoIcon from '@mui/icons-material/Info';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { Tab, Tabs } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function getCurrentTab(path: string): string {
  const firstPathSegment = path.split('/')[1];

  switch (firstPathSegment) {
    case 'leaderboard':
      return 'leaderboard';
    case 'tools':
      return 'tools';
    case 'about':
      return 'about';
    default:
      return 'home';
  }
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export interface NavBarProps {
  path: string;
}

export default function NavBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentTab = getCurrentTab(pathname);

  return (
    <Paper
      square
      elevation={3}
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}
    >
      <Tabs
        value={currentTab}
        onChange={(_, newPath) => navigate(newPath)}
        variant="fullWidth"
        TabIndicatorProps={{
          sx: { height: 3 },
        }}
      >
        <Tab
          component={Link}
          to="/"
          value="home"
          icon={<CreateIcon />}
          {...a11yProps(0)}
        />
        <Tab
          component={Link}
          to="/leaderboard"
          value="leaderboard"
          icon={<LeaderboardIcon />}
          {...a11yProps(1)}
        />
        <Tab
          component={Link}
          to="/tools"
          value="tools"
          icon={<ToolsIcon />}
          {...a11yProps(2)}
        />
        <Tab
          component={Link}
          to="/about"
          value="about"
          icon={<InfoIcon />}
          {...a11yProps(3)}
        />
      </Tabs>
    </Paper>
  );
}
