import WinnerIcon from '@mui/icons-material/EmojiEvents';
import JudgeIcon from '@mui/icons-material/Gavel';
import LoserIcon from '@mui/icons-material/HorizontalRule';
import { Box, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useEffect, useMemo, useState } from 'react';
import { Heading } from '../../components/Heading';
import { Spinner } from '../../components/Spinner';
import { useAuth } from '../../contexts/authContext';
import RatingTypeProvider from '../../contexts/numberOfGlassesContext';
import { Match, onUserMatchesSnapshot } from '../../firebase/api';
import MedalIcon from '../../icons/MedalIcon';
import { theme } from '../../theme';
import { LoginPage } from '../LoginPage/LoginPage';
import { MatchList } from './MatchList';

export function getRatingType(currentTab: number | undefined) {
  switch (currentTab) {
    case 1:
      return 7;
    case 2:
      return 9;
    case 3:
      return 11;
    case 4:
      return 13;
    default:
      return undefined;
  }
}

const styles = {
  tabs: {
    width: '100%',

    '& > div': {
      marginX: 2,
      borderBottom: 1,
      borderColor: 'divider',
    },

    '& .MuiTab-root': {
      minWidth: 0,
      flexGrow: 1,
      flexBasis: 0,
      maxWidth: 'none',
    },
  },
  numberCircle: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    padding: 0.25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    color: 'white',
    backgroundColor: theme.palette.action.active,
  },
};

export default function ProfilePage() {
  const { user, isUserLoggedIn } = useAuth();
  const [userMatches, setUserMatches] = useState<Match[] | undefined>(
    undefined,
  );
  const [currentTab, setCurrentTab] = useState(0);

  const confirmedMatches = useMemo(() => {
    return userMatches?.filter(match => match.status === 'confirmed');
  }, [userMatches]);

  const unconfirmedWinnerOrLoserMatches = useMemo(() => {
    return userMatches?.filter(
      match =>
        match.status === 'pending' &&
        (match.winner.id === user?.id || match.loser.id === user?.id),
    );
  }, [user?.id, userMatches]);

  const hasUnconfirmedMatches =
    unconfirmedWinnerOrLoserMatches !== undefined &&
    unconfirmedWinnerOrLoserMatches.length > 0;

  const unconfirmedRatingDelta =
    user != null
      ? Math.round(user.unconfirmedRating) - Math.round(user.rating)
      : 0;

  const ratingType = getRatingType(currentTab);
  const matchList =
    ratingType !== undefined
      ? userMatches?.filter(match => match.numberOfGlasses === ratingType)
      : userMatches;

  const wins = confirmedMatches?.filter(
    match => match.winner.id === user?.id,
  ).length;
  const losses = confirmedMatches?.filter(
    match => match.loser.id === user?.id,
  ).length;
  const judgings = confirmedMatches?.filter(
    match => match.judge?.id === user?.id,
  ).length;

  const matches =
    wins !== undefined && losses !== undefined ? wins + losses : undefined;
  const winRatio =
    wins !== undefined && matches !== undefined
      ? (matches > 0 ? ((wins / matches) * 100).toFixed() : 0) + '%'
      : undefined;
  const lossRatio =
    losses !== undefined && matches !== undefined
      ? (matches > 0 ? ((losses / matches) * 100).toFixed() : 0) + '%'
      : undefined;

  const loading = matchList === undefined;

  useEffect(() => {
    const fetchUserMatches = async () => {
      if (user?.id === undefined) return;

      const unsubscribe = onUserMatchesSnapshot(user.id, setUserMatches);

      return unsubscribe;
    };

    fetchUserMatches();
  }, [user?.id]);

  if (!isUserLoggedIn) return <LoginPage />;

  return (
    <Container component="main" maxWidth="sm">
      <Stack
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Box textAlign="center">
          <Heading>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              {user?.numberOfGlassesMedal !== undefined && (
                <MedalIcon>{user.numberOfGlassesMedal}</MedalIcon>
              )}
              {user?.nameLabel ?? 'Anonym'}
            </Box>
          </Heading>
          <Typography>{user?.associationLabel}</Typography>
          <Box display="flex" justifyContent="center" gap={0.5}>
            <Typography>{Math.round(user?.rating ?? 0)}</Typography>
            {hasUnconfirmedMatches && (
              <Typography sx={{ opacity: 0.5 }}>
                ({unconfirmedRatingDelta >= 0 ? '+' : ''}
                {unconfirmedRatingDelta})
              </Typography>
            )}
          </Box>
        </Box>

        <Stack direction="row" gap={3} mt={3}>
          <Box display="flex">
            <Typography component="span">{wins}</Typography>
            <WinnerIcon color="success" />
          </Box>
          <Box display="flex">
            <Typography component="span">{losses}</Typography>
            <LoserIcon color="error" />
          </Box>
          <Box display="flex">
            <Typography component="span">{judgings}</Typography>
            <JudgeIcon color="action" />
          </Box>
        </Stack>

        <Stack direction="row" gap={3} mt={2}>
          <Box display="flex" gap={0.5}>
            <Typography>{Math.round(user?.rating7 ?? 0)}</Typography>
            <Box sx={{ mt: 0.125 }}>
              <Box sx={styles.numberCircle}>7</Box>
            </Box>
          </Box>
          <Box display="flex" gap={0.5}>
            <Typography>{Math.round(user?.rating9 ?? 0)}</Typography>
            <Box sx={{ mt: 0.125 }}>
              <Box sx={styles.numberCircle}>9</Box>
            </Box>
          </Box>
          <Box display="flex" gap={0.5}>
            <Typography>{Math.round(user?.rating11 ?? 0)}</Typography>
            <Box sx={{ mt: 0.125 }}>
              <Box sx={styles.numberCircle}>11</Box>
            </Box>
          </Box>
          <Box display="flex" gap={0.5}>
            <Typography>{Math.round(user?.rating13 ?? 0)}</Typography>
            <Box sx={{ mt: 0.125 }}>
              <Box sx={styles.numberCircle}>13</Box>
            </Box>
          </Box>
        </Stack>

        <Stack direction="row" gap={3} mt={2}>
          <Typography>{matches} matcher</Typography>
          <Box display="flex">
            <Typography>{winRatio}</Typography>
            <WinnerIcon color="success" />
            <Typography mx={0.5}>/</Typography>
            <Typography>{lossRatio}</Typography>
            <LoserIcon color="error" />
          </Box>
        </Stack>

        <Typography variant="h5" mt={4}>
          Matcher
        </Typography>

        {hasUnconfirmedMatches && user !== null && (
          <Paper
            variant="outlined"
            sx={{ width: '100%', textAlign: 'center', pt: 2, mb: 2 }}
          >
            <Typography variant="body2">Inväntar bekräftelse</Typography>
            <MatchList
              matches={unconfirmedWinnerOrLoserMatches.toReversed()}
              user={user}
              pendingHasOpacity={false}
            />
          </Paper>
        )}

        <Tabs
          value={currentTab}
          onChange={(_, newValue) => setCurrentTab(newValue)}
          aria-label="tabs"
          sx={styles.tabs}
        >
          <Tab value={0} label="Alla" />
          <Tab value={1} label="7" />
          <Tab value={2} label="9" />
          <Tab value={3} label="11" />
          <Tab value={4} label="13" />
        </Tabs>

        {!loading ? (
          user !== null ? (
            matchList.length > 0 ? (
              <RatingTypeProvider ratingType={ratingType}>
                <MatchList matches={matchList} user={user} />
              </RatingTypeProvider>
            ) : (
              <Typography variant="body2" p={2}>
                Inga matcher än
              </Typography>
            )
          ) : null
        ) : (
          <Spinner />
        )}
      </Stack>
    </Container>
  );
}
