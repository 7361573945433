import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  FilterOptionsState,
  InputAdornment,
  TextField,
} from '@mui/material';
import { matchSorter } from 'match-sorter';

const filterOptions = (
  options: readonly string[],
  state: FilterOptionsState<string>,
) => {
  if (state.inputValue === '') {
    return [];
  }

  return matchSorter(options, state.inputValue, {
    baseSort: (a, b) => a.item.localeCompare(b.item),
  }).slice(0, 5);
};

export interface PlayerNameAutocompleteProps {
  index: number;
  playerName: string;
  names: string[];
  setNames: (names: string[]) => void;
  playerOptions: readonly string[];
}

export default function PlayerNameAutocomplete({
  index,
  playerName,
  names,
  setNames,
  playerOptions,
}: PlayerNameAutocompleteProps) {
  return (
    <Autocomplete
      value={playerName}
      onChange={(_, newValue) => {
        const newPlayerNames = [...names];
        newPlayerNames[index] = newValue;
        setNames(newPlayerNames);
      }}
      options={playerOptions}
      filterOptions={filterOptions}
      sx={[index === 1 && { mt: -2.75 }]}
      freeSolo
      disableClearable
      openOnFocus
      renderInput={params => (
        <TextField
          {...params}
          label={`Capsare ${index + 1}`}
          onChange={event => {
            const newPlayerNames = [...names];
            newPlayerNames[index] = event.target.value;
            setNames(newPlayerNames);
          }}
          helperText={
            index === 0
              ? 'Börja med capsaren närmast till vänster om dig'
              : undefined
          }
          autoFocus={index > 0}
          required
          InputProps={{
            ...params.InputProps,
            endAdornment:
              names.length > 1 ? (
                <InputAdornment position="end" sx={{ mr: 0.5 }}>
                  <CloseIcon
                    fontSize="small"
                    aria-label="Ta bort capsare"
                    onClick={() => {
                      const newNames = [...names];
                      newNames.splice(index, 1);
                      setNames(newNames);
                    }}
                  />
                </InputAdornment>
              ) : undefined,
          }}
        />
      )}
    />
  );
}
