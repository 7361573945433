import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MouseEvent } from 'react';

export type PointSystem = 'points' | 'lives';

const styles = {
  toggleButtonGroup: {
    '& > button.Mui-selected': {
      backgroundColor: 'primary.main',
      color: 'white',

      '&:hover': {
        backgroundColor: 'primary.dark',
      },
    },
  },
};

export interface PointSystemToggleProps {
  value: PointSystem;
  onChange: (newValue: PointSystem) => void;
}

export default function PointSystemToggle({
  onChange,
  ...props
}: PointSystemToggleProps) {
  const handleChange = (_event: MouseEvent, newValue: PointSystem) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      onChange={handleChange}
      aria-label="Point system toggle"
      exclusive
      fullWidth
      sx={styles.toggleButtonGroup}
      {...props}
    >
      <ToggleButton value="points">Poäng</ToggleButton>
      <ToggleButton value="lives">Liv</ToggleButton>
    </ToggleButtonGroup>
  );
}
