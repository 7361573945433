import { useMemo } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { AboutPage } from './AboutPage/AboutPage';
import { HomePage } from './HomePage/HomePage';
import { LeaderboardPage } from './LeaderboardPage/LeaderboardPage';
import RootPage from './RootPage/RootPage';
import ToolsPage from './ToolsPage/ToolsPage';

const BrowserRoutes = () => {
  const location = useLocation();

  const ProfileBackgroundComponent = useMemo(() => {
    const previousPath = location.state?.prevPathname ?? '/';

    switch (previousPath) {
      case '/leaderboard':
        return <LeaderboardPage />;
      case '/tools':
        return <ToolsPage />;
      case '/about':
        return <AboutPage />;
      case '/':
      default:
        return <HomePage />;
    }
  }, [location.state?.prevPathname]);

  return (
    <Routes>
      <Route path="/" element={<RootPage />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/profile" element={ProfileBackgroundComponent} />
        <Route path="/leaderboard" element={<LeaderboardPage />} />
        <Route path="/tools/*" element={<ToolsPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Route>
    </Routes>
  );
};

export const Router = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes />
    </BrowserRouter>
  );
};
