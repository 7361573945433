import { Box } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { ProfileDrawer } from '../../components/Header/ProfileDrawer';
import NavBar from '../../components/NavBar';
import { useAuth } from '../../contexts/authContext';

export default function RootPage() {
  const { user } = useAuth();
  const { pathname, state: locationState } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Header badgeCount={user?.matchesToConfirm.length ?? 0} />
      <Box sx={{ pb: 6 }}>
        <Outlet />
      </Box>
      <NavBar />
      <ProfileDrawer
        open={pathname === '/profile'}
        onClose={() => navigate(locationState?.prevPathname ?? '/')}
      />
    </>
  );
}
