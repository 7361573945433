import { Box } from '@mui/material';
import { ReactNode } from 'react';

const styles = {
  iconWrapper: {
    position: 'relative',
    display: 'flex',
    marginLeft: '-5%',
    marginRight: '-5%',
    width: 'fit-content',

    '& > svg': {
      height: '100%',
    },
  },
  childrenWrapper: {
    position: 'absolute',
    top: 19,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 11,
  },
};

export interface MedalIconProps {
  children?: ReactNode;
}

export default function MedalIcon({ children }: MedalIconProps) {
  return (
    <Box sx={styles.iconWrapper}>
      <svg width="46px" height="46px" viewBox="0 0 120 120">
        <g>
          <circle
            style={{ fill: '#FFC54D' }}
            cx="60"
            cy="83.7"
            r="23.3"
          ></circle>
          <circle
            style={{ fill: '#EDB24A' }}
            cx="60"
            cy="83.7"
            r="18.7"
          ></circle>
          <path
            style={{ fill: '#FFC54D' }}
            d="M60,54.4c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S62.2,54.4,60,54.4z M60,61.1c-1.5,0-2.6-1.2-2.6-2.6 s1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6S61.5,61.1,60,61.1z"
          ></path>
          <path
            style={{ fill: '#5195FF' }}
            d="M82.2,15.7v31.2c0,0.9-0.6,1.8-1.6,2.3l-11.9,5.6L61.7,58c-1,0.5-2.3,0.5-3.3,0l-6.9-3.3l-11.9-5.6 c-1-0.5-1.6-1.4-1.6-2.3V15.7c0-1.5,1.5-2.7,3.3-2.7h37.7C80.7,13,82.2,14.2,82.2,15.7z"
          ></path>
          <path
            style={{ fill: '#F46262' }}
            d="M68.6,13v41.8L61.7,58c-1,0.5-2.3,0.5-3.3,0l-6.9-3.3V13H68.6z"
          ></path>
        </g>
      </svg>

      <Box sx={styles.childrenWrapper}>{children}</Box>
    </Box>
  );
}
