import JudgeIcon from '@mui/icons-material/Gavel';
import { Box, Stack } from '@mui/material';

const styles = {
  judge: {
    alignItems: 'center',
    width: 'fit-content',
    zIndex: 1,
  },
  head: {
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '1.5rem',
    backgroundColor: '#ccc',
  },
  body: {
    height: '1.875rem',
    width: '3rem',
    borderRadius: '1.875rem 1.875rem 0 0',
    backgroundColor: '#ccc',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default function Judge() {
  return (
    <Stack sx={styles.judge}>
      <Box sx={styles.head} />
      <Stack sx={styles.body}>
        <JudgeIcon fontSize="small" sx={{ color: 'white' }} />
      </Stack>
    </Stack>
  );
}
