import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { useAuth } from '../../contexts/authContext';
import ProfilePage from '../../pages/ProfilePage/ProfilePage';
import Drawer from '../Drawer';
import { AccountMenu } from './AccountMenu';

export interface ProfileDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const ProfileDrawer = (props: ProfileDrawerProps) => {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  return (
    <Drawer
      actionButton={
        user !== null && (
          <>
            <IconButton
              onClick={event => setAnchorEl(event.currentTarget)}
              size="large"
              aria-haspopup="true"
              aria-controls={isMenuOpen ? 'account-menu' : undefined}
              aria-expanded={isMenuOpen ? 'true' : undefined}
            >
              <MoreVertIcon />
            </IconButton>

            <AccountMenu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={() => setAnchorEl(null)}
            />
          </>
        )
      }
      {...props}
    >
      <ProfilePage />
    </Drawer>
  );
};
